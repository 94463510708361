/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.action-item {
  --background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    var(--ion-color-light);
  align-items: flex-start;
  height: 33px;
}

.ionic-selectable-has-placeholder .ionic-selectable-value-item {
  color: #07172f !important;
}

.align-items-flex-end {
  align-items: flex-end;
  -webkit-align-items: flex-end;
}

.sq-card-message {
  display: none;
}

.sq-card-wrapper .sq-card-message {
  margin-bottom: 0px !important;
}

.sq-card-wrapper .sq-card-message.sq-visible {
  display: block;
}

.spiner-center {
  position: absolute;
  top: 50%;
  left: 47%;
}

.visibility-none {
  visibility: hidden;
}

.display-device-image-modal {
  height: -webkit-fill-available;
  object-fit: contain;
  width: 100%;
}

.perPageSelectWrapper {
  padding-top: 4px !important
}

.status-pending {
  background: #F1C40F
}

.status-shipped {
  background: #839192
}

.status-completed {
  background: #5ed13b
}

.status-paid {
  background: #229954
}

.status-PaymentInProgress {
  background: #3498DB
}

.status-Pending-Return {
  background: #ef6d18
}

.status-Ready-to {
  background: #34495E
}

.status-unrepairable {
  background: #C0392B
}

.status-received {
  background: #148F77
}

.status-canceled {
  background: #f51f1f
}

@import "~video.js/dist/video-js.css";
@import "~@ng-select/ng-select/themes/material.theme.css";

.ng-option {
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
  }

  b {
    font-weight: normal;
  }
}

.ng-value {
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
  }

  b {
    font-weight: normal;
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background: var(--ion-color-primary);
}

.select-device-config-modal-styles {
  --width: 50%;

  .grade-name-p {
    color: var(--ion-color-primary);
    font-size: 18px;
    margin-bottom: 2px;
  }

  .condition-column {
    ion-item {
      --min-height: 16;

      ion-icon {
        min-width: 10px;
        max-width: 10px;
        margin-right: 12px;
        margin-top: 0;
        margin-bottom: 0;
        align-self: flex-start;
      }

      ion-label {
        align-self: flex-start;
        margin-top: 0;
        margin-bottom: 0;
      }
    }


  }

  @media (max-width: 775px) {
    --width: 100%;

    // height: 100%;
    .condition-column {
      padding-left: 40px;
    }

    .modalHeader {
      h2 {
        font-size: 1rem;
        font-weight: bolder;
      }
    }

    .modalContent {
      padding: 1rem;

      .pricing {
        * {
          font-size: 1rem;
        }
      }
    }
  }
}

.contact-popover {
  width: 1px;
}

.popover-content {
  --width: 500px !important;
  background: #5ed13b;
}

.my-custom-alert-width {
  .alert-wrapper {
    min-width: 250px !important;
  }

  @media (min-width: 500px) {
    .alert-wrapper {
      min-width: 450px !important;
    }
  }
}
// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro&display=swap");

@font-face {
  font-family: 'Montserrat-Medium'; //This is what we are going to call
  src: url('../assets/fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBold'; //This is what we are going to call
  src: url('../assets/fonts/Montserrat-SemiBold.ttf');
}

:root[mode="ios"],
:root[mode="md"] {
  --ion-font-family: "Rubik", -apple-system, sans-serif !important;
  font-family: "Rubik", -apple-system, sans-serif !important;
}

.montserrat-semi-bold-font {
  --ion-font-family: "Montserrat-SemiBold", -apple-system, sans-serif !important;
  font-family: "Montserrat-SemiBold", -apple-system, sans-serif !important;
}

.montserrat-semi-medium-font {
  --ion-font-family: "Montserrat-Medium", -apple-system, sans-serif !important;
  font-family: "Montserrat-Medium", -apple-system, sans-serif !important;
}

// :root {
//   --ion-font-family: "Montserrat-SemiBold" !important;
//   font-family: 'Montserrat-SemiBold' !important; //This is what we are going to call
//   --ion-default-font: "Montserrat-SemiBold" !important
// }

/** Ionic CSS Variables **/
:root {
  --ion-border-color: #dee2e6;
  /** primary **/
  --ion-color-primary: #ff4200;
  --ion-color-primary-rgb: 236, 57, 46;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #c3380a;
  --ion-color-primary-tint: #ff5300;

  /** secondary **/
  --ion-color-secondary: #333333;
  --ion-color-secondary-rgb: 51, 51, 51;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1f1f1f;
  --ion-color-secondary-tint: #595959;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ee2d47;
  --ion-color-danger-rgb: 238, 45, 71;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c73246;
  --ion-color-danger-tint: #f84d64;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #f5f5f5;

  // A horizontal flexible button space for a variable number of buttons.
  // Looks good on all screens and goes vertical on mobile.
  .ios {
    ion-card-title {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .cartFloatButton {
    position: absolute;
    bottom: 30px;
    right: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    min-width: 95px;
  }

  .cart-button-fab {
    @media (max-width: 640px) {
      margin-left: 0.75rem;
      margin-bottom: 0.75rem;
      pointer-events: all;
    }
  }

  // apply this class to ion-button
  .button {

    // put ALL button content in a div with this class. DO NOT USE SLOTS.
    .button-content-override {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      // supports icons, or lack thereof.
      ion-icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      @media (max-width: 340px) {
        gap: 0;

        ion-icon {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  .back-button-fab {
    position: fixed;
    top: 0;
    left: 0;

    margin: 0.5rem;

    @media (max-width: 640px) {
      top: unset;
      bottom: 1rem;
    }
  }

  .buttonFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    &.center {
      justify-content: center !important;
    }

    @media (max-width: 640px) {
      flex-direction: column;

      .button {
        width: 100%;
        max-width: 230px;

        .button-content-override {
          width: 100%;
          justify-content: space-between !important;
        }
      }
    }

    &.alwaysVertical {
      flex-direction: column !important;
      flex-wrap: nowrap !important;

      .button {
        width: 100% !important;

        .button-content-override {
          width: 100% !important;
          align-items: center;

          #text {
            flex-grow: 2 !important;
            text-align: left;
          }
        }
      }
    }

    &.growButtons {
      .button {
        width: max-content !important;
        max-width: unset !important;
      }
    }
  }

  .order {
    margin-bottom: 1rem;

    border: none;
    box-shadow: none;

    .order-content-override {
      display: flex;
      flex-direction: column;
      width: 100%;

      box-sizing: border-box;

      // border: 1px solid var(--ion-color-medium);
      border-radius: 5px;
      overflow: hidden;

      &[dropped="true"] {
        .dropdownButton {
          transform: rotate(-180deg);
        }

        .dropdownButton .icon {
          transform: rotate(-180deg);
        }

        .innerContent {
          height: max-content;
          padding: 1rem;

          border-bottom: 1px solid var(--ion-border-color);
        }
      }

      .headerContent {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem;

        @media (max-width: 680px) {
          flex-direction: column;
          gap: 1rem;
        }

        //   border-bottom: 1px solid var(--ion-color-medium);

        background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
        var(--ion-color-light);

        .headerTac {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          height: 100%;
          // padding: 1rem;
          width: 100%;

          h4 {
            text-transform: uppercase;
            font-weight: bold;
            margin: 0;

            span {
              font-weight: normal;
            }
          }

          p {
            margin: 0;
          }

          &.end h4:first-child {
            margin-bottom: 0.05rem !important;
          }
        }

        .dropdownButton {
          .icon {
            transition: all 0.3s linear;
          }
        }
      }

      .innerContent {
        padding: 0;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        overflow: hidden;
        height: 0;

        @media (max-width: 520px) {
          flex-direction: column;
          padding: 0;

          .textArea {
            width: 100%;

            h1,
            h3 {
              align-self: center;
            }
          }

          .buttonFlex {
            align-self: unset !important;
            margin-top: 1rem;
            width: 100%;

            align-items: flex-start;
          }
        }

        .textArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;

          flex-grow: 2;

          h1 {
            font-weight: bold;

            @media (max-width: 520px) {
              margin-top: 0.5rem;
            }
          }

          h3 {
            letter-spacing: 0.05rem;

            @media (max-width: 520px) {
              margin-bottom: 0.5rem;
            }
          }

          .orderItems {
            margin: 0;
            padding: 0;

            width: 90%;

            @media (max-width: 520px) {
              width: 100%;
            }

            list-style: none;

            .orderItem {
              display: flex;
              flex-direction: row;
              align-items: center;

              position: relative;

              width: 100%;

              overflow: hidden;

              border-bottom: 1px solid var(--ion-color-medium);

              .thumbnailImage {
                position: absolute;
                top: 45%;
                right: 0;

                width: 50%;
                height: unset;
                z-index: -1;
                opacity: 45%;
              }

              .innerContent {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                h2 {
                  // font-weight: 500;
                  font-size: 1.2rem;
                }

                @media (max-width: 380px) {
                  align-self: flex-start;
                  padding-top: 3%;

                  p {
                    margin: 0;
                  }
                }

                p {
                  text-transform: uppercase;
                }

                font-weight: bold;
                //   align-self: flex-start;
              }
            }
          }
        }

        .buttonFlex {
          align-self: flex-start;

          @media (max-width: 640px) {
            align-items: center;
            margin-bottom: 1rem;

            .button {
              width: 90% !important;
              max-width: unset !important;
            }
          }
        }
      }
    }
  }

  .view-inner-content {
    display: none;

    &[display="true"] {
      display: block;
    }
  }

  // Applies to ion-card to make them look good on all screens.
  .responsiveCard {
    width: 1000px;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 640px) {
      max-width: 95vw;
    }
  }

  .general-modal-styles {
    .modal-wrapper {
      min-height: 50vh;
      height: max-content;
      max-height: 100vh;

      form {
        height: max-content;

        display: flex;
        flex-direction: column;
        padding: 1rem;
        align-items: flex-start;

        .locationInformation {
          padding: 1.5rem;
          flex-grow: 2;
        }

        .buttonFlex {
          width: 100%;
          justify-content: flex-end !important;

          &.center {
            justify-content: center !important;
          }

          align-items: center !important;
          gap: 1rem;
        }

        ion-item {
          width: 100%;
          height: max-content;
        }
      }

      @media (max-width: 765px) {
        height: 100vh;

        form {
          padding: 0;
          justify-content: flex-start;
        }
      }

      ion-content {
        height: max-content;
      }
    }
  }

  .add-admin-modal-styles {
    .modal-wrapper {
      min-height: max-content;
      height: 30%;

      ion-content {
        height: max-content;
      }
    }
  }

  // styling specifically for the edit-caregory modal.
  .edit-category-modal-styles {
    .modal-wrapper {
      min-height: 93%;
    }
  }

  // styling specifically for the product-details modal.
  .product-details-modal-styles {
    ion-backdrop {
      visibility: visible !important;
    }

    .priceOutput {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 1rem;
      gap: 1rem;
      flex-wrap: wrap;

      width: max-content;
      max-width: 90%;
      margin: auto;

      justify-content: center;

      .noNote {
        padding: 0 1rem;
      }

      .pricing {
        //   width: calc(50% - 4rem);

        .priceNote {
          margin-right: 1rem;
        }
      }

      .input-wrapper {

        slot,
        .itemFlex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;
        }
      }
    }

    .colorImgFlex {
      display: flex;
      align-items: center;
      justify-content: center !important;

      * {
        margin: 0 0.5rem 0 0.5rem;
      }
    }

    .modal-wrapper {
      min-height: 93%;
      width: 65%;

      max-width: 1000px;

      // Product Details modal
      @media (max-width: 775px) {
        width: 100%;

        .modalHeader {
          h2 {
            font-size: 1rem;
            font-weight: bolder;
          }
        }

        .modalContent {
          padding: 1rem;

          .pricing {
            * {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  // styles specifically for the cart-config modal.
  .cart-config-modal-styles {
    .modal-wrapper {
      min-height: 93%;
      width: 65%;

      max-width: 1000px;

      .modalContent {
        .modalForm {
          width: 90%;
          margin: auto;
        }
      }

      @media (max-width: 775px) {
        width: 100%;
        height: 100%;

        .modalHeader {
          h2 {
            font-size: 1rem;
            font-weight: bolder;
          }
        }

        .modalContent {
          padding: 1rem;

          .pricing {
            * {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  .popover-styles {
    ion-backdrop {
      visibility: visible !important;
    }

    top: 0% !important;

    .popover-content {
      width: 50%;
    }
  }

  .page-link:hover {
    z-index: 2;
    color: --ion-color-primary-tint;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
    color: #b92c24;
  }

  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #ec392e;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #ff4b31;
    border-color: #b92c24;
  }
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #428cff;
//     --ion-color-primary-rgb: 66,140,255;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #3a7be0;
//     --ion-color-primary-tint: #5598ff;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80,200,255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106,100,255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47,223,117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255,213,52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255,73,97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152,154,162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0,0,0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18,18,18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255,255,255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

ion-toolbar {
  --color: #6a6b6d;
  --background: #ffffff;
}

.ion-item-icon {
  background: var(--ion-color-primary);
  /* border-radius: 43%; */
  color: #fff;
  padding: 5px 13px;
  margin-right: 6px;
  border-radius: 24px;
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ngx-pagination .current {
  background: var(--ion-color-primary) !important;
}

.ngx-pagination {
  text-align: end !important;
}

.cursor-pointer {
  cursor: pointer;
}

.required {
  .ng-placeholder::after {
    content: '*';
    color: red;
    font-size: 14px
  }
}

.contact-popover {
  --offset-y: -10px
}

.popover-content {
  --width: 500px !important;
  background: #5ed13b;
}

.navbar-light {
  .navbar-toggler {
    padding: 0;
    color: #ffff;

    &[aria-expanded="true"] {
      .navbar-toggler-icon {
        background-image: url('./../assets/icon/close.png') !important;
      }
    }

    .navbar-toggler-icon {
      background-image: url('./../assets/icon/navbar-new.png') !important;
      padding: 0;
      font-size: 1.7em;
    }

  }
}